<template>
  <div v-if="is_ready">
    <div v-if="!isLandscape" class="mobile">
      <div class="header-wrap">
        <img class="logo" :src="doLang('logo_white')" />
        <img
          class="btn"
          @click="openDrawer"
          src="@assets/images/mobile/drawer_icon.png"
        />
      </div>
      <drawer
        :visible.sync="dialogVisible"
        :headerShow="true"
        header-background="#f5f5f5"
        title-color="#000"
        main-background="#EBEEF5"
        :footerShow="true"
        footer-height="60px"
        footer-background="#f5f5f5"
        width="500px"
        height="350px"
        align="top"
        close-on-click-modal
        :loading.sync="loading"
        loadingColor="#ff6700"
      >
        <!--内容区-->
        <div class="drawer-content">
          <div
            v-for="(nav, index) in nav_info"
            :key="index"
            class="drawer-content-item"
          >
            <a :href="nav.link">
              <div v-bind:class="{ active: nav.active }">{{ nav.title }}</div>
            </a>
          </div>
        </div>
      </drawer>
      <div class="body-wrap">
        <swiper :options="swiperOption">
          <swiper-slide
            class="swiper-item"
            v-for="(slide, index) in swiperSlides"
            :key="index"
          >
            <!-- <img :style="{ 'min-height': innerHeight }" :src="slide.bg" /> -->
            <img
              class="image"
              :src="slide.image"
              :style="{
                top: slide.style === 1 ? '80px' : '80px',
                padding: slide.style === 1 ? '0' : '0 20%',
              }"
            />
            <img
              class="bg"
              :style="{ 'min-height': innerHeight }"
              :src="slide.bg"
            />
            <div class="bottom-box">
              <p class="title" v-html="slide.title">
                <span class="title_2">{{ slide.title_2 }}</span>
              </p>
              <p class="content" v-html="slide.content"></p>
              <img
                class="btn-img"
                :src="doLang('download_btn_mobile_64')"
                @click="downLoadApp(64, -1)"
              />
              <img
                class="btn-img"
                :src="doLang('download_btn_mobile_32')"
                @click="downLoadApp(32, -1)"
              />
              <div v-if="version_info != null" class="version_info_div">
                <p class="version_name">
                  {{ doLang("version_name") }}：{{ version_info.version_name }}
                </p>
                <p class="update_time">
                  {{ doLang("update_time") }}：{{
                    version_info.update_time | formatDate
                  }}
                </p>
                <a
                  v-for="i in version_info.multi"
                  :key="i"
                  class="multi_link"
                  href="javascript:;"
                  @click="downLoadApp(32, i)"
                  >{{ doLang("multi") }}{{ i + 1 }}</a
                >
              </div>
              <div v-if="showLog" class="log-box">
                <p class="text">{{ doLang("not_support_ios_system") }}</p>
                <p class="btn" @click="closeLog">{{ doLang("i_know") }}</p>
              </div>
            </div>
            <div class="btn-box">
              <img src="@/assets/images/mobile/down_btn.png" />
            </div>
          </swiper-slide>
          <swiper-slide class="swiper-item">
            <div class="foot-wrap">
              <div v-if="is_show" class="business">
                <p>
                  {{ doLang("business_cooperation") }} |
                  <a href="mailto:BD@smartgaga.com">BD@smartgaga.com</a>
                </p>
              </div>
              <div v-if="is_show">
                <div v-for="(qq, index) in qq_info" :key="index">
                  <div class="qq-group">
                    <img src="@/assets/images/mobile/QQ_icon.png" />
                    <p>
                      <a :href="qq.link">{{ qq.title }}</a>
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="is_show" class="friends-link">
                <p class="fl-title">{{ doLang("friends_link") }}：</p>
                <div v-for="(friend, index) in friends_info" :key="index">
                  <a
                    :href="friend.link"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><p>{{ friend.title }}</p></a
                  >
                </div>
              </div>
              <div v-if="is_show_about" class="company">
                <p v-if="is_show">深圳市智多互动科技有限公司</p>
                <p v-if="is_show">公司地址：深圳市南山区高新南四道034号高新工业村W1A栋308</p>
                <p v-if="is_show">公司电话：075586957325</p>
                <p><a href="http://beian.miit.gov.cn">粤ICP备19063526号</a></p>
                <p id="wjbeian">
                  <a
                    target="_blank"
                    href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502005107"
                    ><img
                      src="https://static.gsxnj.cn/images/img/index/beianlogo.png"
                      style="float: left"
                    />粤公网安备 44030502005107号</a
                  >
                </p>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div v-else class="landscape">
      <img
        src="https://static.gsxnj.cn/images/img/index/rotatescreen.e24c012f.png"
      />
      <p>
        {{
          doLang(
            "for_a_better_experience_please_use_the_vertical_screen_to_browse"
          )
        }}
      </p>
    </div>
    <div class="language_set">
      <ul>
        <li><a href="/?language=zh-cn">中文</a></li>
        <li><a href="/?language=en-us">english</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import drawer from "@components/drawer.vue";
import {
  isAndroid,
  isNull,
  init,
  setBrowserLanguage,
  getBrowserLanguage,
  lang,
} from "@/utils/index";
import { request } from "../../utils/http";

export default {
  name: "mobile",
  components: {
    swiper,
    swiperSlide,
    drawer,
    request,
  },
  data() {
    return {
      qq_info: null,
      nav_info: null,
      friends_info: null,
      version_info: null,
      is_show_about: true,
      is_show: true,
      is_ready: false,
      default_download_channel: "default",
      swiperOption: {
        // swiper configs 所有的配置同swiper官方api配置
        notNextTick: true, // notNextTick是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true
        direction: "vertical", // 垂直方向
        grabCursor: true, // 鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        // setWrapperSize: true, // Swiper使用flexbox布局(display: flex)，开启这个设定会在Wrapper上添加等于slides相加的宽或高，在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        // autoHeight: true, // 自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化
        // slidesPerView: 1, // 设置slider容器能够同时显示的slides数量(carousel模式)。可以设置为数字（可为小数，小数不可loop），或者 'auto'则自动根据slides的宽度来设定数量。loop模式下如果设置为'auto'还需要设置另外一个参数loopedSlides。
        mousewheel: false, // 开启鼠标滚轮控制Swiper切换。可设置鼠标选项，默认值false
        mousewheelControl: false, // 同上
        height: window.innerHeight, // 高度设置，占满设备高度
        resistanceRatio: 0, // 抵抗率。边缘抵抗力的大小比例。值越小抵抗越大越难将slide拖离边缘，0时完全无法拖离。本业务需要
        observeParents: true, // 将observe应用于Swiper的父元素。当Swiper的父元素变化时，例如window.resize，Swiper更新
        // // 如果自行设计了插件，那么插件的一些配置相关参数，也应该出现在这个对象中，如下debugger
        debugger: true,
        // swiper的各种回调函数也可以出现在这个对象中，和swiper官方一样
        slidesPerView: "auto", // 'auto'
        // slidesPerView : 3.7,
        // 如果设置为auto（例如制作全屏展示时的页脚部分），最后一个slide在键盘或鼠标滚动时可能会直接跳到倒数第三个slide，
        // 此时可以手动设置activeIndex解决，如下
        onTransitionEnd: function (swiper) {
          if (swiper.progress === 1) {
            swiper.activeIndex = swiper.slides.length - 1;
          }
        },
      },
      swiperSlides: [
        {
          bg: "https://static.gsxnj.cn/images/img/index/bg_1.231cfcdf.jpg",
          image: "https://static.gsxnj.cn/images/img/index/img_1.61fcfcb8.png",
          title: "安全免费的虚拟手机",
          content: "全球领先的引擎  ·  完美极致的性能  ·  安卓用户的最爱",
        },
        {
          bg: "https://static.gsxnj.cn/images/img/index/bg_2.2ff45617.jpg",
          image: "https://static.gsxnj.cn/images/img/index/img_2.3b0d9ef8.png",
          title: "放心升级安卓",
          title_2: "10.0",
          content: "独创兼容模式，完美运行不兼容安卓10应用",
        },
        {
          bg: "https://static.gsxnj.cn/images/img/index/bg_3.cbbcf1b1.jpg",
          image: "https://static.gsxnj.cn/images/img/index/img_3.bd28676f.png",
          title: "应用游戏稳定多开",
          content: "多个微信同时聊，稳定不闪退",
        },
        {
          bg: "https://static.gsxnj.cn/images/img/index/bg_4.7e8efe60.jpg",
          image: "https://static.gsxnj.cn/images/img/index/img_4.9b193a88.png",
          title: "游戏挂机在线升级",
          content: "息屏不断网，睡觉可挂机",
        },
        {
          bg: "https://static.gsxnj.cn/images/img/index/bg_5.9c7b451d.jpg",
          image: "https://static.gsxnj.cn/images/img/index/img_5.b59228bd.png",
          title: "极致性能媲美真机",
          content: "媲美真机的性能体验",
        },
      ],
      dialogVisible: false,
      loading: false,
      showLog: false,
      isLandscape: false,
    };
  },
  filters: {
    formatDate: function (value) {
      let date = new Date(value * 1000);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      // let h = date.getHours();
      // h = h < 10 ? ('0' + h) : h;
      // let m = date.getMinutes();
      // m = m < 10 ? ('0' + m) : m;
      // let s = date.getSeconds();
      // s = s < 10 ? ('0' + s) : s;
      return y + "." + MM + "." + d;
    },
  },
  computed: {
    innerHeight() {
      // console.log(window.innerHeight)
      return window.innerHeight + "px";
    },
  },
  mounted() {
    // 监听resize方法
    window.addEventListener("resize", this.renderResize, false);
    this.renderResize();
  },
  beforeDestroy() {
    // 移除监听
    window.removeEventListener("resize", this.renderResize, false);
  },
  beforeCreate() {
    init()
      .then((res) => {
        this.$data.is_ready = true;
        var choose_language = this.$route.query.language;
        if (!isNull(choose_language)) {
          setBrowserLanguage(choose_language);
        }
        request("https://www.gsxnj.cn/get_download.php", "get", null, null)
          .then((res) => {
            this.$data.version_info = res;
          })
          .catch((e) => {
            console.log(e);
          });

        request("https://www.gsxnj.cn/get_web_info.php", "get", null, null)
          .then((res) => {
            var web_info;
            if(res.hasOwnProperty('zh-cn')){
              if(res.hasOwnProperty(getBrowserLanguage())){
                web_info = res[getBrowserLanguage()];
              }else{
                web_info = res['en-us'];
              }
            }else{
              web_info = res;
            }
            this.$data.qq_info = web_info.qq_info.list
            this.$data.nav_info = web_info.nav_info.list
            this.$data.friends_info = web_info.friends_info.list
          })
          .catch((e) => {
            console.log(e);
          });
        request("https://www.gsxnj.cn/get_swiper_set.php", "get", null, null)
          .then((res) => {
            var url = window.location.href;
            url = url.replace("http://", "").replace("https://", "");
            url = url.split("/")[0];
            var url_split = url.split(".");
            url =
              url_split[url_split.length - 2] +
              "." +
              url_split[url_split.length - 1];
            console.log(url);
            if (url != 'xuejijy1.cn') {
              url = getBrowserLanguage() == "zh-cn" ? "default" : "default_en";
            }

            if (getBrowserLanguage() != "zh-cn") {
              this.$data.is_show = false;
            }
            console.log(url);
            this.$data.is_show_about = res[url].is_show_about;
            this.$data.default_download_channel =
              res[url].default_download_channel;
            this.$data.swiperSlides = res[url]["mobile"];
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  },
  created() {},
  methods: {
    doLang(key) {
      return lang(key);
    },
    renderResize() {
      this.$nextTick(function () {
        // 判断横竖屏
        let width = document.documentElement.clientWidth;
        let height = document.documentElement.clientHeight;
        // console.log(width, height)
        if (width > height) {
          this.isLandscape = true;
        } else {
          this.isLandscape = false;
        }
      });
    },
    openDrawer() {
      this.dialogVisible = !this.dialogVisible;
    },
    getChannel() {
      var channel = this.$route.query.from_channel;
      if (isNull(channel)) {
        channel = this.$data.default_download_channel;
      }
      return channel;
    },
    downLoadApp(cpu_bit, id) {
      // console.log('downLoadApp')
      if (isAndroid()) {
        var download_url =
          "/download.php?channel=" + this.getChannel() + "&cpu_bit=" + cpu_bit;
        if (id != -1) {
          download_url += "&is_multi=1&muti_num=" + id;
        }
        // 下载
        window.location.href = download_url;
      } else {
        // 提示
        this.showLog = true;
      }
    },
    closeLog() {
      this.showLog = false;
    },
    alertOnBuild() {
      alert("正在建设中！");
    },
  },
};
</script>
<style lang="less">
.header-wrap {
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 9;
  .logo {
    width: 200px;
  }
  .btn {
    width: 20px;
    position: absolute;
    right: 32px;
  }
}
.drawer-content {
  margin: 0 32px 0 38px;
  border-top: 1px solid #e5e5e5;
  padding: 23px 0;
  div {
    padding: 23px 0;
    font-size: 15px;
    text-align: center;
    color: #b3b3b3;
    &.active {
      color: #2637e9;
    }
  }
  .drawer-content-item {
    padding: 0;
  }
}
.body-wrap {
  width: 100%;
  font-size: 0;
  .swiper-item {
    position: relative;
    .image {
      position: absolute;
      top: 55px;
      width: 100%;
      padding: 0 15%;
    }
    .bg {
      width: 100%;
      height: 100%;
    }
    .bottom-box {
      width: 100%;
      position: absolute;
      top: 62%;
      text-align: center;
      .title {
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 800;
        color: rgba(255, 255, 255, 1);
        line-height: 28px;
        margin-bottom: 15px;
        span {
          font-size: 45px;
          font-family: PingFang SC;
          font-weight: 200;
          color: rgba(255, 255, 255, 1);
          line-height: 53px;
        }
      }
      .content {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 300;
        color: rgba(255, 255, 255, 1);
        line-height: 15px;
        margin-bottom: 20px;
      }
      .btn-img {
        width: 143px;
        margin-right: 1px;
      }
      .log-box {
        position: absolute;
        left: 50%;
        margin-left: -125px;
        bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 250px;
        height: 70px;
        background: url("https://static.gsxnj.cn/images/img/index/bubble-winodw.aa168f69.png")
          no-repeat;
        background-size: 100% 100%;
        padding-bottom: 5px;
        .text {
          font-size: 15px;
          font-family: PingFang SC;
          color: rgba(255, 255, 255, 1);
        }
        .btn {
          font-size: 12px;
          font-family: PingFang SC;
          color: rgba(255, 255, 255, 1);
          border: 1px solid #fff;
          border-radius: 6px;
          margin-left: 20px;
          padding: 5px 10px;
        }
      }
      .version_info_div {
        font-size: 8px;
        color: #ffffff;
        margin: 10px;
        p {
          display: inline;
          margin: 5px;
        }
        .multi_link {
          color: #ababab;
          border: 1px solid #888;
          border-radius: 7px;
          margin-left: 3px;
          padding: 1px 3px;
        }
      }
    }
    .btn-box {
      width: 100%;
      position: absolute;
      bottom: 19px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 14px;
        height: 14px;
      }
    }
    .swiper-slide {
      overflow: auto;
    }
  }
  .foot-wrap {
    text-align: center;
    background-color: #1b1b1b;
    padding: 50px 0 30px;
    .business {
      font-size: 18px;
      font-weight: bold;
      color: rgba(253, 253, 253, 1);
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 25px;
    }
    .qq-group {
      font-size: 18px;
      font-weight: bold;
      color: rgba(253, 253, 253, 1);
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 25px;
      img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }
    }
    .friends-link {
      font-size: 14px;
      font-weight: 300;
      color: rgba(86, 86, 86, 1);
      margin-bottom: 10px;
      display: block;
      align-items: center;
      justify-content: center;
      line-height: 25px;
      .fl-title {
        text-align: left;
        padding-left: 65px;
      }
      div {
        margin-left: 10px;
      }
    }
    .company {
      font-size: 14px;
      font-weight: 300;
      color: rgba(86, 86, 86, 1);
      #wjbeian {
        width: 60%;
        margin: auto;
      }
    }
  }
}
.landscape {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
  text-align: center;
  img {
    width: 80px;
    height: 80px;
    margin-top: 40px;
  }
  p {
    font-size: 12px;
    color: #fff;
    margin-top: 10px;
  }
}
.language_set{
  z-index: 100;
  position: fixed;
  top: 70px;
  right: 10px;
  background-color: #4aa6fc;
  -webkit-box-shadow: 0 1px 5px rgba(0,0,0,.2);
  box-shadow: 0 1px 5px rgba(0,0,0,.2);
  border-radius: 2px;
  ul{
    li{
      font-size: 10px;
      position: relative;
      text-align: center;
      overflow: hidden;
      padding: 15px 5px;
      color: white;
    }
    li:hover{
      background-color: white;
      color: black;
    }
  }
}
</style>
