
<!--* 例子 (简单）
 <drawer
   title="测试"
   :visible.sync='dialogVisible'
   width="500px"
   close-on-click-modal>
 </drawer>
 例子（完整属性）
 <drawer
   :visible.sync='dialogVisible'
   :headerShow="true"
   header-background="#f5f5f5"
   title-color="#000"
   main-background="#EBEEF5"
   :footerShow="true"
   footer-height="60px"
   footer-background="#f5f5f5"
   width="500px"
   height="350px"
   align="right"
   close-on-click-modal
   :loading.sync="loading"
   loadingColor="#ff6700"
   @close="ce"
   @closed="ce"
   @open="ce"
   @opend="ce">
   <div>
     <p>内容</p>
   </div>
   <div slot="footer">
     <p>底部</p>
   </div>
 </drawer>
-->

<!--功能代码-->
<template>
  <transition
    name="drawer"
    @before-enter="drawerBeforeEnter"
    @enter="drawerEnter"
    @after-enter="drawerAfterEnter"
    @leave="drawerleave"
    @before-leave="drawerbeforeLeave"
    v-on:after-leave="drawerafterLeave"
  >
    <div class="drawer" v-if="visible" @click="drawerHide">
      <transition
        name="drawerC"
        @before-enter="beforeEnter"
        @enter="enter"
        @after-enter="afterEnter"
        @leave="leave"
        @after-leave="afterLeave"
      >
        <div
          :class="['Eject',aligns == 'left'?'EjectLeft':aligns == 'right'?'EjectRight':aligns == 'top'?'EjectTop':aligns == 'bottom'?'EjectBottom':'EjectRight']"
          :style="{'width':widths}"
          v-if="jdShow"
          @click.stop="eject"
          ref="eject"
        >
          <!--加载中-->
          <div class="loading" v-if="loading == undefined?false:loading">
            <div class="loader" title="2">
              <svg
                version="1.1"
                id="loader-1"
                x="0px"
                y="0px"
                width="40px"
                height="40px"
                viewBox="0 0 50 50"
                style="enable-background:new 0 0 50 50;"
              >
                <path
                  d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
                  :fill="loadingColor?loadingColor:'#409EFF'"
                >
                  <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="0 25 25"
                    to="360 25 25"
                    dur="0.8s"
                    repeatCount="indefinite"
                  />
                </path>
              </svg>
              <p style="color: #606266;">加载中</p>
            </div>
          </div>
          <!-- <header
            v-if="headerShow == undefined?true:headerShow"
            :style="{'background':headerBackground?headerBackground:'#fff'}"
          >
            <span
              class="title"
              :style="{'color':titleColor?titleColor+'!important':''}"
            >{{title?title:"标题"}}</span>
            <span
              @click.stop="close"
              class="close"
              v-if="closeBtnShow == undefined?true:closeBtnShow"
            >x</span>
          </header> -->
          <div class="drawer-header-wrap">
            <img class="logo" :src="doLang('logo')" />
            <img class="btn" @click.stop="close" src="@assets/images/mobile/close_btn.png" />
          </div>
          <slot></slot>
        </div>
      </transition>
    </div>
  </transition>
</template>
<script>
import { getBrowserLanguage, lang } from '@/utils/index'
export default {
  name: 'demo',
  props: [
    'title',
    'closeBtnShow',
    'footerShow',
    'footerHeight',
    'width',
    'height',
    'visible',
    'footerBackground',
    'headerShow',
    'headerBackground',
    'titleColor',
    'mainBackground',
    'align',
    'closeOnClickModal',
    'loading',
    'loadingColor'
  ],
  data () {
    return {
      jdShow: false,
      timer: undefined,
      aligns: 'right',
      index: 0,
      heights: '',
      widths: ''
    }
  },
  watch: {
    visible (newVal, oldVal) {
      if (newVal) {
        // 显示的时候
      } else {
      }
    },
    loading (newVal, oldVal) {
      if (newVal) {
        // 显示的时候
      } else {
      }
    }
  },
  created () {
    this.jdShow = this.visible ? this.visible : false
    if (this.align) {
      this.aligns = this.align
      if (this.aligns === 'right' || this.aligns === 'left') {
        if (!this.width) {
          this.widths = '500px'
        } else {
          this.widths = this.width
        }
        this.heights = '100%'
      } else if (this.aligns === 'top' || this.aligns === 'bottom') {
        if (!this.height) {
          this.heights = '350px'
        } else {
          this.heights = this.height
        }
        this.widths = '100%'
      }
    } else {
      this.aligns = 'right'
      if (!this.width) {
        this.widths = '500px'
      } else {
        this.widths = this.width
      }
      this.heights = '100%'
    }
  },
  mounted () {},
  beforeDestroy () {
    clearTimeout(this.timer)
  },
  methods: {
    doLang (key) {
      return lang(key);
    },
    // 关闭按钮
    close () {
      this.timer = setTimeout(() => {
        this.$emit('update:visible', false)
      }, 10)
      this.index = 1
      this.jdShow = false
      this.$emit('close')
      if (this.loading !== undefined) {
        this.$emit('update:loading', false)
      }
    },
    // 点击遮罩层隐藏
    drawerHide () {
      if (this.closeOnClickModal === '') {
        this.close()
      }
    },
    // 阻止侧栏冒泡事件
    eject () {},
    // 侧栏动画开始之前
    beforeEnter (el, time = 0.4) {
      let wid = '500px'
      let hit = '350px'
      if (this.width) {
        wid = this.width
      }
      if (this.height) {
        hit = this.height
      }
      el.style.transition = 'all ' + time + 's ease'
      switch (this.aligns) {
        case 'left':
          el.style.transform = 'translate(-' + wid + ',0px)'
          break
        case 'right':
          el.style.transform = 'translate(' + wid + ',0px)'
          break
        case 'bottom':
          el.style.transform = 'translate(0px,' + hit + ')'
          break
        case 'top':
          el.style.transform = 'translate(0px,-' + hit + ')'
          break
        default:
          alert('目前只支持top,bottom,left,right')
      }
    },
    // 侧栏动画结束
    enter (el, done) {
      // eslint-disable-next-line no-unused-expressions
      el.offsetWidth
      el.style.transform = 'translate(0px,0px)'
      el.style.transition = 'all 0.4s ease'
      done()
    },
    // 侧栏动画结束之后
    afterEnter (el) {},
    // 关闭动画
    leave (el) {
      this.beforeEnter(el, 0.2)
    },
    // 关闭动画结束
    afterLeave () {},
    // -----------------------------------------------------
    // 遮罩动画开始
    drawerBeforeEnter (el) {
      this.$emit('open')
      el.style.backgroundColor = 'rgba(0,0,0,0)'
      el.style.transition = 'all 0.4s ease'
    },
    // 遮罩动画结束
    drawerEnter (el, done) {
      // eslint-disable-next-line no-unused-expressions
      el.offsetWidth
      el.style.backgroundColor = 'rgba(0,0,0,0.4)'
      el.style.transition = 'all 0.4s ease'
      done()
    },
    // 遮罩动画结束之后
    drawerAfterEnter (el) {
      this.jdShow = true
      this.$emit('opend')
    },
    drawerleave (el) {
      el.style.backgroundColor = 'rgba(0,0,0,0)'
      el.style.transition = 'all 0.8s ease'
      this.$emit('closed')
    },
    // 遮罩层隐藏
    drawerbeforeLeave () {
      if (this.index === 0) {
        this.beforeEnter(this.$refs.eject, 0.2)
      }
    },
    // 遮罩层隐藏之后
    drawerafterLeave () {
      clearTimeout(this.timer)
      this.timer = undefined
      this.jdShow = false
      this.index = 0
    }
  }
}
</script>
<style scoped lang="less">
  .drawer{
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0;
    z-index: 2000;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    transition: 1s;
    overflow: hidden;
  }
  .Eject{
    position: absolute;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    transition: 1s;
  }
  .EjectTop{
    left: 0;
    top: 0;
  }
  .EjectBottom{
    left: 0;
    bottom: 0;
  }
  .EjectLeft{
    left: 0;
    top: 0;
  }
  .EjectRight{
    right: 0px;
    top: 0;
    margin: 0;
  }
  .loading{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: hsla(0,0%,100%,.9);
    /*background-color: rgba(0,0,0,0.7);*/
  }
  // header {
  //   padding: 0 20px;
  //   border-bottom: 1px solid #e8eaec;
  //   line-height: 50px;
  //   display: flex;
  // }
  .drawer-header-wrap {
    height: 90px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .logo {
      width: 200px;
    }
    .btn {
      width: 20px;
      position: absolute;
      right: 32px;
    }
  }
  .title{
    flex: 1;
    font-size: 15px;
    color: #17233d;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .close:hover{
    color: #409EFF;
  }
  /*滚动条凹槽的颜色，还可以设置边框属性*/
  ::-webkit-scrollbar-track-piece {
    background-color:#f8f8f8;
  }
  /*滚动条的宽度*/
  ::-webkit-scrollbar {
    width:9px;
    height:9px;
  }
  /*滚动条的设置*/
  ::-webkit-scrollbar-thumb {
    background-color:#C0C4CC;
    background-clip:padding-box;
    min-height:28px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color:#bbb;
  }
  /*加载样式*/
  .loader {
    height: 100px;
    width: 20%;
    text-align: center;
    padding: 1em;
    display: inline-block;
    vertical-align: top;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

</style>
